@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  ::-webkit-scrollbar {
    display: none;
  }
  

  html {
    -ms-overflow-style: none;
    scrollbar-width: none;
    font-family: 'Poppins', sans-serif;
  }

  .table-striped tbody tr:nth-child(even) {
    background: red;
  }

  .placeholder-normal::placeholder {
    font-weight: normal;
  }
}

 @font-face {
    font-family: 'Cabinet Grotesk';
    src: url('../public/fonts/CabinetGrotesk-Regular.woff2') format('woff2'),
         url('../public/fonts/CabinetGrotesk-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Cabinet Grotesk';
    src: url('../public/fonts/CabinetGrotesk-Bold.woff2') format('woff2'),
         url('../public/fonts/CabinetGrotesk-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }
  input:not(:placeholder-shown).error {
    border-color: #D80027 !important;
}
input:not(:placeholder-shown) + label,
textarea:not(:placeholder-shown) + label {
  color: black;
}
textarea:not(:placeholder-shown) {
  border-color: black;
  color: black;
}
input:hover,
textarea:hover {
  color: black;
}

#notificationHeader {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}

#notificationBody {
  margin-top: 10px;
  text-align: center;
}

#imageContainer {
  display: flex;
  align-items: center;
  height: 100px;
  object-fit: contain;
}

.table-striped tr:nth-child(odd),.table-striped-membership tr:nth-child(even) {
  background-color: #f8f7f7;
}

.table-striped tr:nth-child(even), .table-striped-membership tr:nth-child(odd) {
  background-color: #ffffff;
}
.view-details-btn{
  background-color:#205FFF;
}